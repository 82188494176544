import _ from "lodash";
import { toJS } from "mobx";

/**
 * @desc 人脸查询条件处理
 * @param {Object} options
 */
export const faceOptions = options => {
  const {
    limit,
    startTime,
    endTime,
    cameraIds,
    offset,
    sex,
    eyeGlass,
    placeType,
    minId,
    generation,
    feature,
    hat,
    faceMask,
    upColor,
    score,
    upStyle,
    imgId,
    id,
    aids
  } = options;
  // 性别处理-其他
  let sexOtherTags = undefined;
  let sexTags = undefined;
  if (sex === "other") {
    sexOtherTags = Dict.map.sex.map(v => v.value);
  }
  sexTags = sexOtherTags ? sexTags : sex ? [sex] : [];
  // 年龄段处理-其他
  let ageSectionTags, ageOtherSectionTags;
  if (generation === "other") {
    ageOtherSectionTags = Dict.map.generation.map(v => v.value);
  }
  ageSectionTags = ageOtherSectionTags ? ageSectionTags : generation ? [generation] : [];
  // 头部特征处理
  let headTags, headOtherTags;
  if (hat === "119003") {
    headOtherTags = ["119003"];
  } else {
    // hat ? (headTags = [hat]) : undefined;
    hat ? (headTags = hat) : undefined;
  }
  let maskTags, noMaskTags;
  if (faceMask === "100252") {
    noMaskTags = ["100252"];
  } else {
    faceMask ? (maskTags = [faceMask]) : undefined;
  }
  // 上身特征
  let upperColor = [],
    upperStyle = [],
    upperBodyTags = [];
  if (upColor) {
    upperColor = [upColor];
  }
  if (upStyle) {
    upperStyle = [upStyle];
  }
  upperBodyTags = upperColor.concat(upperStyle);
  let data = {
    offset,
    limit,
    startTime,
    endTime,
    sexTags,
    sexOtherTags,
    ageSectionTags,
    ageOtherSectionTags,
    headTags,
    headOtherTags,
    maskTags,
    noMaskTags,
    feature,
    score,
    imgId,
    id,
    aids,
    upperBodyTags,
    placeTags: toJS(placeType) || [],
    cids: cameraIds.map(v => v.cid || v.id)
  };
  if (minId) {
    data.minId = minId;
  }
  if (eyeGlass === "112802") {
    data.noGlassTags = ["112801"];
  } else {
    data.glassTags = eyeGlass ? [eyeGlass] : [];
  }
  return data;
};

export const bodyOptions = options => {
  const {
    offset,
    generation,
    limit,
    startTime,
    endTime,
    cameraIds,
    sex,
    placeType,
    upColor,
    lowerColor,
    goods,
    upperTexture,
    feature,
    lowerTexture,
    minId,
    score,
    hat,
    faceMask,
    eyeGlass,
    imgId,
    id
  } = options;
  let sexOtherTags = undefined;
  let sexTags = undefined;
  if (sex === "other") {
    sexOtherTags = Dict.map.sex.map(v => v.value);
  }
  sexTags = sexOtherTags ? sexTags : sex ? [sex] : [];
  let wearColorTags = [];
  if (upColor) {
    wearColorTags.push(upColor);
  }
  if (lowerColor) {
    wearColorTags.push(lowerColor);
  }
  // 年龄段处理-其他
  let ageSectionTags, ageOtherSectionTags;
  if (generation === "other") {
    ageOtherSectionTags = Dict.map.generation.map(v => v.value);
  }
  ageSectionTags = ageOtherSectionTags ? ageSectionTags : generation ? [generation] : [];

  // 头部特征处理
  let headTags, headOtherTags;
  if (hat === "119003") {
    headOtherTags = ["119003"];
  } else {
    // hat ? (headTags = [hat]) : undefined;
    hat ? (headTags = hat) : undefined;
  }

  let maskTags, noMaskTags;
  if (faceMask === "100252") {
    noMaskTags = ["100252"];
  } else {
    faceMask ? (maskTags = [faceMask]) : undefined;
  }
  var data = {
    offset,
    limit,
    endTime,
    sexTags,
    sexOtherTags,
    bottomsTypeTags: lowerTexture ? [lowerTexture] : [],
    topsTextureTags: upperTexture ? [upperTexture] : [],
    personalEffectsTags: goods ? [goods] : [],
    wearColorTags,
    ageSectionTags,
    ageOtherSectionTags,
    headTags,
    headOtherTags,
    maskTags,
    noMaskTags,
    feature,
    score,
    imgId,
    startTime,
    id,
    cids: cameraIds.map(v => v.cid || v.id),
    placeTags: toJS(placeType) || []
  };
  if (minId) {
    data.minId = minId;
  }
  if (eyeGlass === "112802") {
    data.noGlassTags = ["112801"];
  } else {
    data.glassTags = eyeGlass ? [eyeGlass] : [];
  }
  return data;
};

/**
 * @desc 机动车查询条件处理
 * @param {Object} options
 */
export const vehicleOptions = options => {
  const {
    limit,
    plateNo,
    startTime,
    endTime,
    cameraIds,
    plateColor,
    vehicleBrands,
    vehicleClasses,
    vehicleColor,
    minId,
    feature,
    vehicleType,
    imgId,
    score,
    id
  } = options;
  let includeTags = [];
  let orTags = []
  if (plateColor) {
    includeTags.push(plateColor);
  }
  if (vehicleBrands) {
    includeTags.push(vehicleBrands);
  }
  if (vehicleClasses) {
    let ids = Dict.map.vehicleClasses.filter(v => v.value === vehicleClasses)[0].ids;
    orTags = orTags.concat(ids);
  }
  if (vehicleColor) {
    includeTags.push(vehicleColor);
  }
  return (options = {
    limit,
    //offset,
    imgId,
    id,
    startTime,
    plateNo: plateNo ? plateNo : undefined,
    endTime,
    includeDeviceIds: cameraIds ? cameraIds.map(v => v.id) : [],
    includeTags,
    orTags,
    minId,
    feature,
    score,
    vehicleType
  });
};

/**
 * @desc 非机动车查询条件处理
 * @param {Object} options
 */
export const nonVehicleOptions = options => {
  const {
    limit,
    plateNo,
    startTime,
    endTime,
    cameraIds,
    vehicleColor,
    minId,
    vehicleType,
    sex,
    head,
    upColor,
    feature,
    upperTexture,
    imgId,
    id,
    score,
    nonMotorVehicleModel
  } = options;
  let includeTags = [];
  if (nonMotorVehicleModel) {
    includeTags.push(nonMotorVehicleModel);
  }
  if (head) {
    includeTags.push(head);
  }
  if (upColor) {
    includeTags.push(upColor);
  }
  if (upperTexture) {
    includeTags.push(upperTexture);
  }
  if (vehicleColor) {
    includeTags.push(vehicleColor);
  }
  if (sex) {
    includeTags.push(sex);
  }
  return (options = {
    limit,
    startTime,
    plateNo: plateNo ? plateNo : undefined, // 车牌号
    endTime,
    includeDeviceIds: cameraIds.map(v => v.id),
    includeTags,
    minId,
    imgId,
    id,
    vehicleType,
    feature,
    score
  });
};

/**
 * @desc 门禁记录查询
 * @param {Object} options
 */
export const accessControlOptions = options => {
  let { limit, endTime, startTime, cameraIds, keywords, endId, accessTypes, startId } = options;
  // 处理其他
  let exculdedAccessTypes;
  if (accessTypes === "other") {
    exculdedAccessTypes = Dict.map.openType.map(item => item.value);
    accessTypes = undefined;
  } else {
    accessTypes = accessTypes ? [accessTypes] : undefined;
  }
  if (startId) {
    endId = undefined;
  }
  if (endId) {
    startId = undefined;
  }
  return {
    limit,
    startTime: +startTime,
    startId,
    endId,
    endTime: +endTime,
    deviceIds: !!cameraIds.length ? cameraIds.map(v => v.id) : undefined,
    keywords,
    exculdedAccessTypes,
    accessTypes
  };
};
